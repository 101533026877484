/* eslint-disable react/jsx-no-target-blank */
import TableNavigation from 'components/TableNavigation';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import setClassSuffix from '../../../../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../../../../utilities/services/IconManager';
import './styles.scss';

function DocumentsPreview({ files }) {
  const classBase = 'ickyc-documents-preview';
  const setSuffix = setClassSuffix(classBase);
  const [currentPage, setCurrentPage] = useState(1);
  const downloadButtonRef = useRef(null);

  const currentFile = useMemo(() => files?.[currentPage - 1], [files, currentPage]);

  const goToNextPage = e => {
    setCurrentPage(old => old + 1);
    e.stopPropagation();
  };

  const goToPrevPage = e => {
    setCurrentPage(old => old - 1);
    e.stopPropagation();
  };

  const isImage = useMemo(() => {
    const { format } = currentFile;
    return format === 'JPEG' || format === 'PNG' || format === 'JPG';
  }, [currentFile?.format]);

  const downloadDoc = e => {
    e.stopPropagation();
    downloadButtonRef.current.click();
  };

  const contententPreview = useMemo(() => {
    if (isImage) {
      return <img src={currentFile?.previewUrl} alt="image_preview" />;
    } else {
      if (currentFile?.format === 'DOC') {
        return (
          <div className={setSuffix('__local-doc-preview')} onClick={downloadDoc}>
            {currentFile?.originalName}
          </div>
        );
      } else {
        return <iframe src={currentFile?.previewUrl} title="docPreview" height="100%" />;
      }
    }
  }, [currentFile, isImage]);

  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <div className={classBase} onClick={stopPropagation}>
      {files?.length ? (
        <>
          <div className={setSuffix('__header')}>
            <h2>Document Preview</h2>
            <div>
              <a href={currentFile?.downloadUrl} download={currentFile?.originalName} ref={downloadButtonRef}>
                {IconManager.get(IconManager.names.DOWNLOAD)}
              </a>
              <a href={currentFile?.previewUrl} target="_blank">
                {IconManager.get(IconManager.names.LINK)}
              </a>
            </div>
          </div>
          <div className={setSuffix('__content')}>{contententPreview}</div>
          <TableNavigation
            pageSize={1}
            totalNumber={files?.length}
            pageNumber={currentPage}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
          />
        </>
      ) : (
        <div> No Results </div>
      )}
    </div>
  );
}

DocumentsPreview.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      downloadUrl: PropTypes.string,
      format: PropTypes.string,
    }),
  ).isRequired,
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  onPaginationChange: PropTypes.func,
};

DocumentsPreview.defaultProps = {
  onPaginationChange: () => {},
};

export default DocumentsPreview;
